import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form ,Y2020, Y2022 ,Y2017, Y2018, Y2019 , Y2023 , Y2024} from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const SignupSimple = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main> 
      <Grid>
              <Y2024 />
            </Grid>
       <Grid>
              <Y2023 />
            </Grid>
       <Grid>
              <Y2022 />
            </Grid>
            <Grid>
              <Form />
            </Grid>
            <Grid>
              <Y2020 />
            </Grid>
            <Grid>
              <Y2019 />
            </Grid>
            <Grid>
              <Y2018 />
            </Grid> 
            <Grid>
              <Y2017 />
            </Grid>
    </Main>
  );
};

export default SignupSimple;
